<template>
  <div class="swiperBox">
    <div class="tit">客户心声</div>
    <div class="swiperList">
      <div
        class="swiper"
        ref="mySwipers"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <div class="item">
              <div class="topText">
                {{ item.desc }}
              </div>
              <div class="botText">
                <span>{{ item.company }} {{ item.position }}</span>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div class="item">
              <div class="topText">
                携手两年，切身感受到wahool的专业、全面、高效，真正实现共赢。愿我们创新不止，扶摇直上。
              </div>
              <div class="botText">
                <span>YOSUDA CEO</span> <span>David</span>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="item">
              <div class="topText">
                专业的团队，高效的工作，全面的服务，洞察市场先机，解决客户痛点，亚马逊直播业务板块的优秀合作伙伴。新的一年，携手再创佳绩。
              </div>
              <div class="botText">
                <span>OROLAY 运营总监</span> <span>府彧</span>
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="btns" @click="toLiveServePath">合作洽谈</div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
const router = useRouter();
const toLiveServePath = () => {
  router.push({
    path: '/livestreamPage',
  });
};
const mySwipers = ref();
const swiperList = ref([
  {
    desc: 'Wahool抓住了细分领域里的市场机遇，成为行业龙头。在亚马逊直播蓝海里给客户创造了新的价值。拥有一支充满能量的战狼团队，专业且负责，行胜于言，以客户利益至上。',
    company: '范泰克科技创新有限公司',
    position: 'CEO',
    name: '杜波',
  },
  {
    desc: '携手两年，切身感受到wahool的专业、全面、高效，真正实现共赢。愿我们创新不止，扶摇直上。',
    company: 'YOSUDA',
    position: 'CEO',
    name: 'David',
  },
  {
    desc: '专业的团队，高效的工作，全面的服务，洞察市场先机，解决客户痛点，亚马逊直播业务板块的优秀合作伙伴。新的一年，携手再创佳绩。',
    company: 'OROLAY',
    position: '运营总监',
    name: '府彧',
  },
  {
    desc: '在直播这个红火的赛道，既有机遇又充满了风险，作为出海直播的领路人，Wahool作为行业内最专业优质的服务商，让出海的品牌看到了灯塔一般指引的方向。帮助出海的品牌在亚马逊上乘最顺的风，破最高的浪。祝Wahool在新的一年里，保持先锋，助力品牌出海再创辉煌。',
    company: 'Ecoflow',
    position: '海外电商负责人',
    name: '刘聪',
  },
  {
    desc: 'Wahool作为Nebula亚马逊直播的唯一合作伙伴，成功帮助Nebula取得了业务突破，开拓了高客单价产品线的销售新渠道',
    company: 'Nebula by Anker',
    position: '品牌经理',
    name: 'Christy',
  },
  {
    desc: '建立合作以来，丸货一直是我们值得信任的合作伙伴。在亚马逊直播领域内，丸货拥有优质的主播资源，站内日常促销能够助力产品爆单，充分给予了高效专业的支持，推动业务发展。',
    company: 'Govee Sales Director',
    position: '',
    name: 'Lorain',
  },
  {
    desc: '机缘，在合作中生根，情谊，在合作中加深。愿新的一年，我们继续再接再厉，合作双赢，创造新的业绩增长!',
    company: 'Eufy by Anker Brand Manager',
    position: '',
    name: 'Abby',
  },
  {
    desc: '有一种合作叫做真诚，有一种发展可以无限，有一种伙伴可以互利共赢，有一种祝福在你我心间，愿Wahool和Bedsure的合作前景可观!',
    company: 'Bedsure Brand Manager',
    position: '',
    name: 'Nora',
  },
]);
var swipers;
onMounted(() => {
  setTimeout(() => {
    swipers = new Swiper(mySwipers.value, {
      slidesPerView: 3,
      spaceBetween: 150,
      slidesPerGroup: 1,
      streamerBox: false,
      loop: true,
      centeredSlides: true,
      loopFillGroupWithBlank: true,
      loopAdditionalSlides: 4,
      autoplay: { disableOnInteraction: false, delay: 5000 },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      // on: {
      //   click: function (e, a) {
      //     // 在此处实现相关业务逻辑
      //     if (e.path[2].attributes[1].value == 'video') {
      //       openVideoUrl.value = e.path[2].attributes[2].value;
      //       videoRef.value = true;
      //     }
      //   },
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }, 1500);
});
console.log(swipers, 'swiper----swiper');
</script>
<style lang="less" scoped>
.btns {
  width: 140px;
  height: 40px;
  background: #fb5d43;
  border-radius: 20px;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin: 124px auto 0;
  cursor: pointer;
}
.swiper-button-next {
  position: absolute;
  right: 40px;
  top: 50%;
}
.swiper-button-prev {
  position: absolute;
  left: 40px;
  top: 50%;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  color: #111111;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #111111;
}
.imgtop {
  margin-bottom: 100px;
}
.swiperBox {
  width: 100%;
  padding: 100px 0;
  font-family: 'Lantinghei SC';
  background: rgba(251, 93, 67, 0.05);
  .tit {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    color: #111111;
    text-align: center;
    margin-bottom: 80px;
  }
  .swiperList {
    width: 100%;
    // padding: 0 120px;
    padding: 0 150px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .swiper-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .swiper-slide {
        width: 32%;
        .item {
          height: 320px;
          // padding: 36px 40px 36px;
          box-sizing: border-box;
          width: 100%;
          background: #fb5d43;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .topText {
            font-family: 'Lantinghei SC';
            color: #fff;
            font-weight: 500;
          }
          .botText {
            display: flex;
            flex-direction: column;
            font-family: 'Lantinghei SC';
            color: #ffffff;
            font-weight: 600;
          }
        }
      }
    }
  }
}
@media screen and(min-width:1200px) and (max-width: 1400px) {
  .item {
    padding: 20px;
    .topText {
      font-size: 12px;
      line-height: 16px;
    }
    .botText {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
@media screen and(min-width:1400px) {
  .item {
    padding: 20px;
    .topText {
      font-size: 14px;
      line-height: 19px;
    }
    .botText {
      font-size: 14px;
      line-height: 28px;
    }
  }
}
</style>
