<template>
  <div class="brandBox">
    <div class="tit">生态合作伙伴</div>
    <div class="brandList brandTop">
      <div class="brandItems" @click="openUrl(1)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot1.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(2)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot2.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(3)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot3.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(4)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot4.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(5)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot5.png"
          alt=""
        />
      </div>
    </div>
    <div class="brandList">
      <div class="brandItems" @click="openUrl(6)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot6.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(7)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot7.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(8)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot8.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(9)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot9.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(10)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot10.png"
          alt=""
        />
      </div>
    </div>
    <div class="brandList" style="margin-top:70px">
      <div class="brandItems" @click="openUrl(11)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot11.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(12)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot12.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(13)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot13.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(14)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot14.png"
          alt=""
        />
      </div>
      <div class="brandItems" @click="openUrl(15)">
        <img
          src="https://video.wahool.com/b_website/live/bIconFoot15.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script setup>
const openUrl = (index) => {
  switch (index) {
    case 1:
      window.open('https://www.lingxing.com/?invite=wahl');
      break;
    // case 2:
    //   window.open('https://www.baidu.com');
    //   break;
    case 3:
      window.open('https://www.moonsees.com/');
      break;
    case 4:
      window.open('https://www.gcbnt.com/');
      break;
    case 5:
      window.open('https://www.hongquekol.com/');
      break;
    case 6:
      window.open('https://www.sellersprite.com');
      break;
    case 7:
      window.open('https://www.pingpongx.com');
      break;
    case 8:
      window.open('https://www.onkeys.cn');
      break;
    case 9:
      window.open('https://www.oalur.com/user/register?referral=PPCHANNELIVT');
      break;
    case 10:
      window.open('https://www.cogolinks.com');
      break;
      case 11:
      window.open('http://tracking.payoneer.com/SH42V');
      break;
      case 12:
      window.open('https://u.evatmaster.com/1Pz7oa');
      break;
      case 13:
      window.open('https://globalpay.163.com/');
      break;
      case 14:
      window.open('https://kuajingai.com/#/');
      break;
      case 15:
      window.open('http://www.damaip.com/');
      break;
  }
};
</script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and(max-width:1300px) {
  .brandBox {
    padding: 100px 0 275px;
  }
}
@media screen and(min-width:1300px) and(max-width:1660px) {
  .brandBox {
    padding: 100px 5% 275px;
  }
}
@media screen and(min-width:1660px) {
  .brandBox {
    padding: 100px 120px 232px;
  }
}
.brandBox {
  width: 100%;
  //  padding: 100px 120px 232px;
  box-sizing: border-box;

  .tit {
    font-family: 'Lantinghei SC';
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #111111;
    text-align: center;
  }
  .brandTop {
    margin-top: 80px;
    margin-bottom: 70px;
  }
  .brandList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .brandItems {
      width: 240px;
      height: 90px;
      cursor: pointer;
      img {
        width: 240px;
        height: 90px;
        object-fit: cover;
      }
    }
  }
}
</style>
