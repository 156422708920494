<template>
  <div class="swiperBox">
    <div class="swiperList">
      <div
        class="swiper"
        ref="mySwiper"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <div class="item">
              <div class="img imgtop">
                <img :src="item.picTop" alt="" />
              </div>
              <div class="img">
                <img :src="item.picBot" alt="" />
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div class="item">
              <div class="img imgtop">
                <img
                  src="https://video.wahool.com/b_website/live/swiper3.png"
                  alt=""
                />
              </div>
              <div class="img">
                <img
                  src="https://video.wahool.com/b_website/live/swiper4.png"
                  alt=""
                />
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
const mySwiper = ref();
const swiperList = ref([
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper1.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper2.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper3.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper4.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper5.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper6.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper7.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper8.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper9.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper10.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper11.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper12.png',
  },
  {
    picTop: 'https://video.wahool.com/b_website/live/liveSwiper13.png',
    picBot: 'https://video.wahool.com/b_website/live/liveSwiper14.png',
  },
]);
var swiper;
onMounted(() => {
  setTimeout(() => {
    swiper = new Swiper(mySwiper.value, {
      slidesPerView: 3,
      spaceBetween: 150,
      slidesPerGroup: 1,
      streamerBox: false,
      loop: true,
      centeredSlides: true,
      loopFillGroupWithBlank: true,
      loopAdditionalSlides: 4,
      autoplay: { disableOnInteraction: false, delay: 5000 },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      // on: {
      //   click: function (e, a) {
      //     // 在此处实现相关业务逻辑
      //     if (e.path[2].attributes[1].value == 'video') {
      //       openVideoUrl.value = e.path[2].attributes[2].value;
      //       videoRef.value = true;
      //     }
      //   },
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }, 1500);
});
console.log(swiper, 'swiper----swiper');
</script>
<style lang="less" scoped>
.swiper-button-next {
  position: absolute;
  right: 40px;
  top: 50%;
}
.swiper-button-prev {
  position: absolute;
  left: 40px;
  top: 50%;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  color: #111111;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #111111;
}
.imgtop {
  margin-bottom: 100px;
}
.swiperBox {
  width: 100%;
  padding: 160px 0;
  background: rgba(237, 237, 237, 0.5);
  .swiperList {
    width: 100%;
    // padding: 0 120px;
    padding: 0 150px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .swiper-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .swiper-slide {
        width: 32%;
        .item {
          .img {
            img {
              width: 100%;
              border-radius: 16px;
              // height: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
