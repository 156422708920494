<template>
  <div class="brandBox">
    <div class="tit">合作品牌</div>
    <div class="brandList brandTop">
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon1.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon2.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon3.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon4.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon5.png" alt="" />
      </div>
    </div>
    <div class="brandList">
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon6.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon7.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon8.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon9.png" alt="" />
      </div>
      <div class="brandItem">
        <img src="https://video.wahool.com/b_website/live/bIcon10.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and(max-width:1300px) {
  .brandBox {
    padding: 100px 0 275px;
  }
}
@media screen and(min-width:1300px) and(max-width:1660px) {
  .brandBox {
    padding: 100px 5% 275px;
  }
}
@media screen and(min-width:1660px) {
  .brandBox {
    padding: 100px 120px 275px;
  }
}
.brandBox {
  width: 100%;
  // padding: 100px 120px 275px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tit {
    font-family: 'Lantinghei SC';
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #111111;
    text-align: center;
  }
  .brandTop {
    margin-top: 120px;
    margin-bottom: 70px;
  }
  .brandList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .brandItem {
      width: 240px;
      height: 90px;
      img {
        width: 240px;
        height: 90px;
        object-fit: cover;
      }
    }
  }
}
</style>
