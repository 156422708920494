<template>
  <div class="tiktokCpn">
    <div class="conBox">
      <div class="leftImg">
        <img
          src="https://video.wahool.com/b_website/live/tiktokIcon.png"
          alt=""
        />
      </div>
      <div class="rightText">
        <div class="rightTit">Tiktok Live</div>
        <div class="rightDesc">
          Wahool作为海外达人直播赛道的先行者，不断强化聚焦美国本土达人TikTok直播，不仅和Tiktok团队深度合作，同时也为优质品牌提供直播运营服务，和卖家共同探索跨境直播营销的新形态，伴随着不断突破从传播量到销售转化的峰值，已为品牌逐步打造出更加高效的营销路径。
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and (max-width: 1300px) {
  .conBox {
    width: 100%;
    justify-content: space-around;
    // padding: 0 5%;
    box-sizing: border-box;
    .rightText {
      margin-left: 0;
      .rightDesc {
        max-width: 300px;
      }
    }
  }
}
@media screen and(min-width:1300px) {
  .conBox {
    width: 1300px;
    .rightText {
      margin-left: 170px;
      .rightDesc {
        max-width: 400px;
      }
    }
  }
}
.tiktokCpn {
  width: 100%;
  height: 900px;
  background: #fb5d43;
  display: flex;
  justify-content: center;
  font-family: 'Lantinghei SC';
  overflow: hidden;
  .conBox {
    display: flex;
    .leftImg {
      width: 700px;
      height: 900px;
      padding-top: 200px;
      box-sizing: border-box;
      img {
        width: 700px;
        height: 864px;
      }
    }
    .rightText {
      height: 100%;
      padding-top: 401px;
      box-sizing: border-box;
      .rightTit {
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        font-family: 'Gotham';
        color: #ffffff;
      }
      .rightDesc {
        // max-width: 400px;
        margin-top: 30px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
      }
    }
  }
}
</style>
