<template>
  <div class="liveVideo">
    <div class="cardBox">
      <div class="itemLeft">
        <div class="title">Amazon Live</div>
        <div class="desc">
          Wahool自2020年底涉猎亚马逊直播领域，在飞速发展的跨境直播蓝海赛道中，以碾压之势成为行业领军机构，手握海量独家亚马逊顶流达人资源及自带亚马逊基因的资深团队，助力2000+跨境卖家品牌出海，锚定品牌在海外本土的影响力，为中国品牌保驾护航。
        </div>
      </div>
      <div class="itemRight">
        <img
          class="macPic"
          src="https://video.wahool.com/website/images/theAgency/macBac.png"
          alt=""
        />
        <video
          controls
          class="gitPic video"
          src="https://video.wahool.com/website/images/theAgency/Influencer-Loop.mp4"
          webkit-playsinline="true"
          playsinline="true"
          x5-video-player-type="h5-page"
          muted=""
          autoplay="autoplay"
          loop="loop"
        ></video>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.liveVideo {
  width: 100%;
  padding: 170px 0 133px 0;
  display: flex;
  font-family: 'Lantinghei SC';
  justify-content: center;
  .cardBox {
    max-width: 1440px;
    display: flex;
    .itemLeft {
      padding-top: 40px;
      max-width: 400px;
      .title {
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        color: #111111;
      }
      .desc {
        margin-top: 40px;
        font-size: 20px;
        line-height: 28px;
        color: #666666;
      }
    }
    .itemRight {
      position: relative;
      margin-left: 113px;
      .macPic {
        width: 600px;
      }
      .gitPic {
        width: 473px;
        height: 299px;
        top: 13px;
        position: absolute;
        left: 50%;
        object-fit: fill;
        background-size: cover;
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
